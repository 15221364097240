import * as React from "react";
import { graphql } from "gatsby";

import Approchheader from "../components/approach/header";
import ApprochFeature from "../components/approach/approch_feature";
import Accordian from "../components/approach/approch_accordian";
import Accordionheader from "../components/approach/accordian_header";
import Footer from "../components/footer";
import BookCta from "../components/cta/bookcta";
import GoTop from "../components/goTop";
import WhatsappButton from "../components/whatsapp";
import Seo from "../components/seo";

const ApproachPage = ({ data }) => {
  const description = `LKECC’s chosen philosophy and developmental theory is the Early Years Foundation Stage. We work with children on a one-to-one basis, giving them the opportunity to bring out their best selves in a safe, nurturing and challenging environment`;

  const { accordian, framework, hero } = data.gcms.approches[0];
  const { navigation } = data.gcms.links[0];

  return (
    <>
      <Seo description={description} title={`Our Approach`} />

      <Approchheader
        navigation={navigation}
        title={hero.herotitle}
        description={hero.description}
      />
      <ApprochFeature
        framework={framework[0]}
        subframework={framework.slice(1)}
      />
      <Accordionheader />
      <Accordian accordianData={accordian.slice(1)} />
      {/* <div className=" bg-custom_yello-light_2  w-full bg-waves_bottom bg-cover bg-no-repeat py-4 sm:p-10"></div> */}
      {/* <BookCta /> */}
      <WhatsappButton />
      <GoTop />
      <Footer navigation={navigation} />
    </>
  );
};

export const query = graphql`
  query approchQuery {
    gcms {
      approches(locales: en) {
        hero {
          herotitle
          description
        }
        framework {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_SubBasicComponent {
            id
            subTitle
            subDescription
          }
        }
        accordian {
          ... on GraphCMS_BasicComponent {
            title
            description
          }
          ... on GraphCMS_SubBasicComponent {
            id
            subTitle
            subDescription
          }
        }
      }
      links(locales: en) {
        navigation {
          id
          slug
          name
        }
      }
    }
  }
`;

export default ApproachPage;
